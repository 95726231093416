import React from 'react'
import './App.css';
import { useState } from 'react';
import Header from './Components/Header/Header';
import NavigationList from './Components/NavigationList'
import { Button, Container } from '@mui/material'
import Footer from './Components/Footer/Footer'
import { Helmet } from 'react-helmet'

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navigationItems = [
    { label: 'Home', link: '/' },
    { label: 'Dashboard', link: '/about' },
    { label: 'Live', link: '/services' },
    { label: 'Settings', link: '/contact' },
    { label: 'Profile', link: '/contact' },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='App'>

    <Helmet>
        <title>Sesh Hub | Home</title>
        <meta name="description" content="Sesh Hub Home" />
    </Helmet>
      <Header />
      <div className="App-content">
        <div>
          <NavigationList items={navigationItems} />
          {/* Add the toggle button */}
        </div>
        <div className="MainContent">
          <Container maxWidth="lg">
            {/* Add the rest of your content here */}
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
