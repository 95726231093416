import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Register from '../Register';
import Button from '@mui/material/Button';

function Footer() {
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false); // Start with dialog closed

  const openDialog = () => {
    setOpenRegisterDialog(true);
  };

  const user = true;

  if (user) {
    return null;
  }

  return (
    <div>
      <header style={headerStyle}>
        <div style={logoStyle}>
          <Typography>
            Join the Hub! Discover the best live streams anywhere.
          </Typography>
        </div>
        <div style={buttonContainerStyle}>
          <Button
            variant='contained'
            style={signupButtonStyle}
            onClick={openDialog}
          >
            SIGN UP
          </Button>
        </div>
      </header>

      {!!openRegisterDialog && (
        <Register
          open={openRegisterDialog}
          handleClose={() => setOpenRegisterDialog(false)}
        />
      )}
    </div>
  );
}

const headerStyle = {
  backgroundColor: '#FFF',
  color: '#222',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  position: 'sticky',
  flexDirection: 'row',
  height: '5vh',    
  bottom: 0,  
};

const logoStyle = {
  flex: '1',
  display:'flex',
  
};

const buttonContainerStyle = {
  display: 'flex',
  height: '40px',
};

const commonButtonStyle = {
    border: 'none',
    padding: '10px 20px',
    marginLeft: '10px',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'None',
    height: 35
  };
  
  const signupButtonStyle = {
    ...commonButtonStyle,
    backgroundColor: '#35e078',
    boxShadow: '0 0 10px rgba(53, 224, 120, 0.7)', // Glow effect
  };

export default Footer;
