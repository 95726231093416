import React, { useState } from 'react';
import HeaderLogo from '../../assets/headerLogo.svg'
import Register from '../Register';
import Login from '../Login'
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

function Header() {

const [openRegisterDialog, setOpenRegisterDialog] = useState(false)
const [openLoginDialog, setOpenLoginDialog] = useState(false)

  return (
    <header style={headerStyle}>
      
      <div style={logoStyle}>
        <img src={HeaderLogo} alt="Logo" width="160" height="50" />
        <Divider orientation="vertical" flexItem style={DividerStyle}/>
        <div style={browsePlacement}>
          <Typography>Browse</Typography>
        </div>
      </div>
      <div style={buttonContainerStyle}>
        <Button variant='outlined' style={commonButtonStyle} onClick={() => setOpenLoginDialog(true)}>
          Log In
        </Button>
        <Button variant='contained' style={signupButtonStyle} onClick={() => setOpenRegisterDialog(true)}>
            Sign Up
        </Button>
        <div style={imageLayout}>
        <AccountCircleOutlinedIcon fontSize='large'/>
        </div>
      </div>
      <Register open={openRegisterDialog} handleClose={() => setOpenRegisterDialog(false)} />
      <Login open={openLoginDialog} handleClose={() => setOpenLoginDialog(false)} />
      
    </header>
  );
}

const headerStyle = {
  backgroundColor: '#222',
  color: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  borderBottom: '3px solid #222', 
  position: 'sticky',
  flexDirection: 'row',
  top: 0,
};

const logoStyle = {
  flex: '1',
  display:'flex',
};

const buttonContainerStyle = {
  display: 'flex',
  height: '40px',
};

const browsePlacement = {
  display: 'flex',
  alignItems: 'center'
}

const imageLayout = {
  margin: '0px 10px'
}

const commonButtonStyle = {
    padding: '10px 20px',
    marginLeft: '10px',
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #35e078',
    height: 35,
    color: '#35e078',
    backgroundColor: ''
  };
  
  const signupButtonStyle = {
    ...commonButtonStyle,
    backgroundColor: '#35e078',
    color: '#222',
    boxShadow: '0 0 10px rgba(53, 224, 120, 0.7)', // Glow effect
    border: '1px solid ',
  };

  const DividerStyle = {
    display: 'flex',
    border: '1px solid #222',
    marginLeft: '4vw',
    height: '5vh',
    alignSelf: 'center',
  }

export default Header;
