import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import MenuClose from '@mui/icons-material/MenuOpen';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ExpandIcon from '@mui/icons-material/ExitToApp'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const drawerWidth = 230

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`, // Adjust this value to make the closed drawer wider
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`, // Adjust this value for larger screens
  },
})

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && openedMixin(theme)),
    ...(!open && closedMixin(theme)),
    '& .MuiDrawer-paper': {
      ...(open && openedMixin(theme)),
      ...(!open && closedMixin(theme)),
    },
  })
);

const NavigationList = ({ items }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <CustomDrawer 
      variant="permanent"
      anchor='left'
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'inherit',
          display: 'flex',
        },
      }}
    >
    <Toolbar>
    { open && (
    <Typography variant="overline">
    RECOMMENDED SESH'S
      </Typography>)}
  <div style={{ flexGrow: 1 }} /> {/* This will push the icon to the right when the drawer is open */}
  
  <IconButton onClick={handleDrawerToggle}>
    {open ? (
      <Tooltip title="Collapse">
        <MenuClose /> 
      </Tooltip>
    ) : (
      <Tooltip title="Expand">
        <ExpandIcon />
      </Tooltip>
    )}
  </IconButton>
</Toolbar>


      <Divider />
      <List>
        {['Dashboard', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </CustomDrawer>
  );
};

export default NavigationList;
