import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import HeaderLogo from '../assets/headerLogo.svg'
import { Divider, Typography, FormControlLabel, Checkbox } from '@mui/material'
import { Helmet } from 'react-helmet';

function Login({ open, handleClose }) {

  const [formData, setFormData] = React.useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log(formData);
    // Close the dialog
    handleClose();
  };


    return (
      <Dialog open={open} onClose={handleClose} maxWidth='sm' PaperProps={{style: {...DialogContainer}}}>
        <Helmet>
        <title>Sesh Hub | Login</title>
        <meta name="description" content="Sesh Hub Login" />
    </Helmet>
        <div style={LoginHeader}>
          <img src={HeaderLogo} alt="Logo" width="160" height="50" />
        </div>
        <Divider orientation='horizontal' variant="middle" style={DividerStyle}/>
        <Typography variant='h6' style={HeaderTitle}>
          Welcome Back! 
        </Typography>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.username}
            onChange={handleInputChange}
            InputProps={{ style: TextFieldStyles }}
            size='small'
            required
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={formData.password} 
            onChange={handleInputChange}
            InputProps={{ style: TextFieldStyles }}
            size="small"
            variant="outlined"
            required
          />
          <FormControlLabel
          control={<Checkbox style={checkboxStyle} defaultChecked required />}
          label="I am 18+ years of age"
        />
        </DialogContent>
        <div>
        
          <DialogActions style={DialogFooter}>
            <Button onClick={handleClose} style={emptyButton}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} style={ButtonStyle} variant="contained">
              Log In
            </Button>
          </DialogActions>
        </div>
        <Typography variant='subtitle2' style={terms}>
        By signing up, you agree to our Privacy Policy and Terms of Service.
        </Typography>
    </Dialog> 
    );
  }

  const LoginHeader = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const DialogContainer = {
    padding: '10px',
    backgroundColor: '#222',
    borderRadius: '10px',
    border: '1px solid #35e078',
    alignItems: 'center',
    width: '35vw',
  }

  const HeaderTitle = {
    marginTop: '10px',
    color: '#FFF',
  }

  const TextFieldStyles = {
    backgroundColor: '#555',
    marginBottom: '10px'
  }

  const DividerStyle = {
    backgroundColor: '#35e078',
    width: '22vw'   
  }

  const emptyButton = {
    color: '#FFF'
  }

  const ButtonStyle = {
    backgroundColor: '#35e078',
    color: '#222'
  }

  const DialogFooter = {
   justifyContent: 'space-around',
   display: 'flex',
   width: '40vw',
   marginBottom: '10px',
  }

  const checkboxStyle = {
    marginLeft: '15px',
    display: 'flex',
    color: 'white'
  }

  const terms = {
    color: '#FFF',
  }

  export default Login